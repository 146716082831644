import React from "react";
import './spotlights.scss';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Typography from '@material-ui/core/Typography';
import Thumb1 from 'assets/spotlight-1.png';
import Thumb2 from 'assets/spotlight-2.png';
import Thumb3 from 'assets/spotlight-3.png';
import Thumb4 from 'assets/spotlight-4.png';
import Thumb5 from 'assets/spotlight-5.png';
import Thumb6 from 'assets/spotlight-6.png';
import Plus from 'assets/global-plus.png';


export default function Spotlights() {
  return (
    <>
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="global-service-main-cls">
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <img src={Plus} alt="Icon Image" className="plusIcon" />
            <h2 className="heading">Spotlights</h2>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="row-cls">
            {/* First Row */}
            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
              <Typography className="desp">Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis.
                Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. Cras sit</Typography>
            </Grid>
            <Grid item lg={5} md={4} className="xl-hide sm-hide"></Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="xl-hide"></Grid>
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6}>
              <div className="thumb-wrap">
                <img src={Thumb1} alt="Thumb Image" />
                <Typography className="title">curabitur quam</Typography>
              </div>
            </Grid>
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6}>
              <div className="thumb-wrap">
                <img src={Thumb2} alt="Thumb Image" />
                <Typography className="title">spotlight 1</Typography>
              </div>
            </Grid>
            {/* Second Row */}
            <Grid item xl={3} lg={4} className="lg-hide md-hide sm-hide"></Grid>
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6} className="after-matrix">
              <div className="thumb-wrap">
                <img src={Thumb3} alt="Thumb Image" />
                <Typography className="title">curabitur quam</Typography>
              </div>
            </Grid>
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6}>
              <div className="thumb-wrap">
                <img src={Thumb4} alt="Thumb Image" />
                <Typography className="title">lorem ipsum dolor amet</Typography>
              </div>
            </Grid>
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6}></Grid>
            {/* Third Row */}
            <Grid item xl={6} lg={4} md={6} sm={12} xs={12} className="sm-hide md-hide"></Grid>
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6}>
              <div className="thumb-wrap">
                <img src={Thumb5} alt="Thumb Image" />
                <Typography className="title">curabitur quam</Typography>
              </div>
            </Grid>
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6}>
              <div className="thumb-wrap">
                <img src={Thumb6} alt="Thumb Image" />
                <Typography className="title">lacus pharetra</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}


