import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box, Container } from '@material-ui/core';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import GlobalHeroimg from '../../../assets/banner-img.png';
import GlobalVideoImg from '../../../assets/video-img.png';
import './global-header.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function GlobalHeader() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className='global-landing-cls'>
      <div className='global-landing-hero'>
        {/* <grid for image background> */}
        <Grid className='header-div'>
          <Grid container className='header-container' >
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className='header-hero-bg'>
              <div className='blue-round-div'>
                <h1 className='title'>solutions</h1>
                <p className='blue-sec-desp'>whether you’re designing your first mobility program or relocating thousands of executives people each year, cartus delivers proactive dynamic, customized flexible solutions that meet your unique needs, everytime.</p>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={2} lg={1} xl={1}></Grid>
          </Grid>
        </Grid>
      </div>



      {/* <about us solutions section> */}
      <Container maxWidth="xl" className='global-service-container'>
        {/* <div className='header-capsules-bg'> */}
        <Grid container className='txt-with-video-container'>
          {/* <Grid item xs={12} sm={12} md={1} lg={2} xl={1}></Grid> */}
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={6} sm={5} md={5} lg={7} xl={6}>
              <h2 className='title1'>streamline workflow with our dependable programs</h2>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h3 className='sub-title'>solve your problem statements today</h3>
                <p className='desptxt'>Innovating ways to optimize, anticipate, and manage the relocation lifecycle, Cartus provides your mobility managers, domestic transferees, and international assignees with the best experience possible.</p>
                <p className='desptxt'>We deliver a holistic client and employee relocation journey through our rapid, flexible technology solutions, which we design and build based on real user feedback following the key tenets of the Agile process.</p>
                <p className='desptxt'>Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='img-box'>
                  <Paper elevation={0} className="video-container">
                    <Button className="button-container" onClick={handleClickOpen}>
                      <img src={GlobalVideoImg}
                        alt="video"
                        className="video-img" />
                      <PlayCircleOutlineIcon fontSize="large" className="play-button-icon" />
                    </Button>
                    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                      <Box className="popup-overlay">
                        <IconButton className="popup-close-icon" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                        <DialogContent >
                          <Grid container className='dialog-content'>
                            <div className="iframe-class">
                              <iframe
                                width="560"
                                height="349"
                                className="responsive-iframe"
                                src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                            </div>
                          </Grid>
                        </DialogContent>
                      </Box>
                    </Dialog>
                    <Grid>
                      <Paper elevation={0} className='intro-container-bg'>
                        <Typography className="video-title">our solutions</Typography>
                        <Typography className="video-subtitle">how cartus helped magnolia sheia</Typography>
                      </Paper>
                    </Grid>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* </div> */}
    </div>
  )
}


