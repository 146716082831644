import React from 'react'
import Layout from '../../staticcomponents/layout'
import './global-landing.scss'
import GlobalHeader from './global-header'
import KeyService from './key-service'
import Spotlights from './spotlights'
import Container from '@material-ui/core/Container'

export default function GlobalLanding() {
    return (
        <>
            <Layout>
                <div className='global-landing-main'>
                    <div className='bg-donuts-left'>
                    <div className='bg-donuts-right'>
                    <GlobalHeader />
                        <Container maxWidth="xl" className='globallanding-container-div'>
                           
                                <KeyService />
                                <Spotlights />
                        </Container>
                        </div>
                    </div>

                </div>
            </Layout>
        </>
    )
}