import React from "react";
import Grid from '@material-ui/core/Grid'
import expertise_icon from '../../../assets/unrelenting-icon.png';
import { Link } from 'gatsby';
import './key-service.scss';

export default function KeyService() {

  return (
    <>
      <Grid container className='key-service-cls'>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container item xs={7} sm={10} md={10} lg={8} xl={5} className='para-1'>
            <h2 className="heading">
              <img src={expertise_icon} alt="expertise icon" className='arrow-img' />
              <span>explore our key services</span>
            </h2>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={10} xl={10} className='para-1'>
            <p className='desp-txt'>Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat. Sed vitae libero vestibulum, dictum turpis in, lobortis libero. In commodo vitae diam vel scelerisque. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='greybox'>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='box-row-1'>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <div className='box title-box'>
              <h3>region</h3>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
            <div className='box box-height-1'>
              <h4>global</h4>
              <p className='custdesp'>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
            <div className='box'>
              <h4>us/canada</h4>
              <p>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
            </div>
          </Grid>
          <Grid item xl={4}></Grid>
        </Grid>

        <div className='capsules--bg'>
          <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className='box-row-2'>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <div className='box title-box'>
                <h3>service categories</h3>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
              <div className='box box-height-1'>
                <h4>lorem ipsum</h4>
                <p className='custdesp'>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
              <div className='box'>
                <h4>sanctus est</h4>
                <p className='custdesp'>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium</p>
              </div>
            </Grid>
            <Grid item xl={2} lg={2} md={6}></Grid>
            <Grid item xl={3} lg={2} md={6}></Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
              <div className='box'>
                <h4>dolore magan</h4>
                <p>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} className="capsules-bg"></Grid>
          </Grid>
        </div>

        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className='box-row-3'>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <div className='box title-box'>
              <h3>move phase</h3>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
            <Link to={'/globalservice/'} role="link" className='container-link'>
              <div className='box box-height-1'>
                <h4>pre-decision</h4>
                <p>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
              </div>
            </Link>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
            <div className='box box-height-2'>
              <h4>departure</h4>
              <p>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
            </div>
          </Grid>
          <Grid item lg={8} md={6} sm={6} xs={6} className='custGrid'></Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
            <div className='box'>
              <h4>destination</h4>
              <p>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
            </div>
          </Grid>
        </Grid>

        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className='box-row-1 box-row-4'>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <div className='box title-box'>
              <h3>user</h3>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
            <div className='box box-height-1'>
              <h4>client</h4>
              <p>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={10}>
            <div className='box'>
              <h4>employees</h4>
              <p>Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}


